import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query Zakat {
    heroImage: file(relativePath: {eq: "project-zakat-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Give your Zakat: Today, human beings are still dying because of the cold and isolation." description="Through its ZAKAT program, UMMATY offers you the possibility to fulfill this obligation in a single click. We ensure that 100% of your Zakat donation amounts (no administration fees applied) are spent on the beneficiaries categories mentioned in the Holy Qur'an." mdxType="SEO" />
    <CauseHero purpose="zakat" title="Give your Zakat" text="« Of their goods, take Zakat, so that you might purify and sanctify them »" footer="[9:103, Qur'an]." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`The Holy Quran mentions eight channels for spending Zakat: "Zakat expenditures are only for the poor and for the needy and for those employed to collect `}{`[zakat]`}{` and for bringing hearts together `}{`[for Islam]`}{` and for freeing captives `}{`[or slaves]`}{` and for those in debt and for the cause of Allah and for the `}{`[stranded]`}{` traveler - an obligation `}{`[imposed]`}{` by Allah. And Allah is Knowing and Wise." `}{`[9:60, Qur'an]`}{`.`}</p>
      <p>{`Through its ZAKAT program, UMMATY offers you the possibility to fulfill this obligation in a single click. We ensure that 100% of your Zakat donation amounts (no administration fees applied) are spent on the beneficiaries categories mentioned in the Holy Qur'an. Through your Zakat, you contribute in helping some of the poorest communities and offer them opportunities to change their living conditions. So purify your wealth and help your brothers and sisters by donating your zakat now.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      